const block = {}
const elObj = require.context('./', false, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  if(!el) return
  block[el.name] = el
})
export default block


